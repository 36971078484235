import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import Helmet from "react-helmet";

function Newsletter({data}) {
    return (
        <div className="podcast_section p-100">
            <Helmet>
                <script async type="text/javascript"
                        src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=WuMaWM"></script>
            </Helmet>
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={12} lg={8} xl={8} xxl={8} className="m-auto text-center">
                        <h2 dangerouslySetInnerHTML={{__html: checkData(data, 'newsletter_title')}}/>
                        <div dangerouslySetInnerHTML={{__html: checkData(data, 'newsletter_txt')}}/>
                        <div className="klaviyo-form-RJGpHC"></div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Newsletter;